function NameBox() {

  return (
    <div className="NameBox">
      <div className="NameBox-Text">
        <h1>Grant Li</h1>
        <p>A software developer from a finance background</p>
      </div>
    </div>
  );
}

export default NameBox;